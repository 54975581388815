import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from '../../linkResolver'

const PreviewPage = () => {
    return (
        <div className="flex items-center justify-center w-screen h-screen fixed top-0 left-0">
            <h1 className="w-full max-w-md md:max-w-3xl px-8 md:px-1 font-semibold text-2xl md:text-3xl lg:text-4xl text-center text-navy z-20">
                Loading preview...
            </h1>
        </div>
    )
}

export default withPrismicPreviewResolver(PreviewPage, [
    {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
    },
])
